import { v4 as uuidv4 } from 'uuid';
import { API_ROUTES } from '../types/api.routes';
import {
	TAccountType,
	TInfoData,
	TMappedCheckbox,
	TMappedCheckboxes
} from '../types/types';
import { ROUTES } from '../types/routes';

async function customFetch(
	input: RequestInfo | URL,
	init?: RequestInit | undefined
): Promise<Response> {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
	const customHeaders = {
		'Accept-Language':
			locale.toLocaleLowerCase() + '-' + locale.toUpperCase(),
		...init?.headers
	};
	if (init?.headers) {
		init.headers = customHeaders;
	}

	return await fetch(input, init);
}

async function fetchRegistrationInfo() {
	const infoData = await customFetch(
		API_ROUTES.REGISTRATION_INFO.URL +
			'?' +
			new URLSearchParams({
				state: localStorage.getItem('state') as string
			}),
		{
			method: API_ROUTES.REGISTRATION_INFO.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonInfoData = await infoData.json();
	if (infoData.status != 200) {
		if (infoData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (infoData.status === 401 || infoData.status === 400) {
			await updateState();
		}
		throw jsonInfoData;
	}
	sessionStorage.setItem('stateInfo', JSON.stringify(jsonInfoData));

	return jsonInfoData;
}

function mapInfoResponse(infoData: TInfoData) {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

	sessionStorage.setItem('stateInfo', JSON.stringify(infoData));

	if (infoData.currentStepId === 'finish') {
		sessionStorage.clear();
		localStorage.clear();
		window.location.reload();
	}

	if (infoData.isEmailVerified && infoData.isEulaAccepted) {
		sessionStorage.setItem('email', infoData.email);
		const isChecked = [false, false, false];
		infoData.checkBoxesResult.forEach(
			(checkBox: TMappedCheckbox, idx: number) =>
				(isChecked[idx] = checkBox.isSelected)
		);
		sessionStorage.setItem(
			'checkBoxes',
			JSON.stringify({
				checkBoxes: isChecked
			})
		);

		if (!infoData.isPhoneVerified) {
			window.location.replace(
				ROUTES.REGISTRATION.PHONE.replace(':locale', locale)
			);
		}
	}

	if (infoData.currentStepId === 'create') {
		window.location.replace(
			ROUTES.REGISTRATION.SUMMARY.replace(':locale', locale)
		);
	}
}

async function updateState() {
	const data = await customFetch(API_ROUTES.REGISTRATION_START.URL, {
		method: API_ROUTES.REGISTRATION_START.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			acctype: 'Personal'
		})
	});
	const jsonData = await data.json();
	if (data.status != 200) {
		if (data.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		throw jsonData;
	}
	localStorage.setItem('state', jsonData.state);

	window.location.reload();
}

export async function fetchRegistrationStart(accountType: TAccountType) {
	const localState = localStorage.getItem('state');

	if (!localState) {
		const data = await customFetch(API_ROUTES.REGISTRATION_START.URL, {
			method: API_ROUTES.REGISTRATION_START.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				acctype: accountType
			})
		});
		const jsonData = await data.json();
		if (data.status != 200) {
			throw jsonData;
		}
		localStorage.setItem('state', jsonData.state);
	}

	const infoData = await customFetch(
		API_ROUTES.REGISTRATION_INFO.URL +
			'?' +
			new URLSearchParams({
				state: localStorage.getItem('state') as string
			}),
		{
			method: API_ROUTES.REGISTRATION_INFO.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonInfoData = await infoData.json();
	if (infoData.status != 200) {
		if (infoData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (infoData.status === 401 || infoData.status === 400) {
			await updateState();
		}
		throw jsonInfoData;
	}
	mapInfoResponse(jsonInfoData);

	return jsonInfoData;
}

export async function fetchRegistrationEula(body: { accType: string }) {
	const localState = localStorage.getItem('state');

	if (!localState) {
		const utm_source = localStorage.getItem('utm_source');
		const utm_medium = localStorage.getItem('utm_medium');

		const data = await customFetch(API_ROUTES.REGISTRATION_START.URL, {
			method: API_ROUTES.REGISTRATION_START.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json',
				f2x_utm: `utm_source=${utm_source}&utm_medium=${utm_medium}`
			},
			body: JSON.stringify({
				acctype: body.accType
			})
		});
		const jsonData = await data.json();
		if (data.status != 200) {
			throw jsonData;
		}
		localStorage.setItem('state', jsonData.state);
	}

	const infoData = await customFetch(
		API_ROUTES.REGISTRATION_INFO.URL +
			'?' +
			new URLSearchParams({
				state: localStorage.getItem('state') as string
			}),
		{
			method: API_ROUTES.REGISTRATION_INFO.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonInfoData = await infoData.json();
	if (infoData.status != 200) {
		if (infoData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (infoData.status === 401 || infoData.status === 400) {
			await updateState();
		}
		throw jsonInfoData;
	}
	mapInfoResponse(jsonInfoData);

	const eulaTextData = await customFetch(
		API_ROUTES.DICTIONARY_BY_ID.URL.replace('{dictId}', 'registration') +
			'?' +
			new URLSearchParams({
				state: localStorage.getItem('state') as string
			}),
		{
			method: API_ROUTES.DICTIONARY_BY_ID.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonEulaTextData = await eulaTextData.json();
	if (eulaTextData.status != 200) {
		if (eulaTextData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (
			eulaTextData.status === 401 ||
			(eulaTextData.status === 400 &&
				jsonEulaTextData.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw jsonEulaTextData;
	}

	const eulaData = await customFetch(
		API_ROUTES.GET_EULA.URL +
			'?' +
			new URLSearchParams({
				state: localState
					? localState
					: (localStorage.getItem('state') as string)
			}),
		{
			method: API_ROUTES.GET_EULA.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonEulaData = await eulaData.json();
	if (eulaData.status != 200) {
		if (eulaData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (eulaData.status === 401 || eulaData.status === 400) {
			await updateState();
		}
		throw jsonEulaData;
	}

	sessionStorage.setItem('checkBoxesText', JSON.stringify(jsonEulaData));

	sessionStorage.setItem(
		'sessionCheckboxesBack',
		JSON.stringify(jsonEulaData)
	);

	return jsonEulaData;
}

export async function fetchSubmitClear() {
	const clearData = await customFetch(API_ROUTES.CLEAR.URL, {
		method: API_ROUTES.CLEAR.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state')
		})
	});

	if (clearData.status != 200) {
		if (clearData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const eulaDataJson = await clearData.json();
		if (
			clearData.status === 401 ||
			(clearData.status === 400 && eulaDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw eulaDataJson;
	}

	return clearData;
}

export async function fetchSubmitFirstStep(data: {
	email: string;
	checkBoxes: TMappedCheckboxes;
	isEmailVerified: boolean;
}) {
	//const navigate = useNavigate();

	if (data.isEmailVerified) {
		return;
	}

	const eulaData = await customFetch(API_ROUTES.SEND_EULA.URL, {
		method: API_ROUTES.SEND_EULA.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			checkBoxesResult: data.checkBoxes
		})
	});

	if (eulaData.status != 200) {
		if (eulaData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const eulaDataJson = await eulaData.json();
		if (
			eulaData.status === 401 ||
			(eulaData.status === 400 && eulaDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw eulaDataJson;
	}

	const codeData = await customFetch(API_ROUTES.SEND_CODE.URL, {
		method: API_ROUTES.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			identityType: 'email',
			identity: data.email
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (
			codeData.status === 401 ||
			(codeData.status === 400 && codeDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw codeDataJson;
	}

	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	sessionStorage.setItem('email', data.email);
	//navigate(ROUTES.REGISTRATION.EMAIL_CODE);

	return eulaData;
}

export async function fetchAskNewEmailCode() {
	const codeData = await customFetch(API_ROUTES.SEND_CODE.URL, {
		method: API_ROUTES.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			identityType: 'email',
			identity: sessionStorage.getItem('email')
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (
			codeData.status === 401 ||
			(codeData.status === 400 && codeDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw codeDataJson;
	}

	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	return codeData;
}

export async function fetchSubmitEmailCode(data: { code: string }) {
	const codeData = await customFetch(API_ROUTES.VERIFY_CODE.URL, {
		method: API_ROUTES.VERIFY_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			identityType: 'email',
			identity: sessionStorage.getItem('email'),
			code: data.code
		})
	});

	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const codeDataJson = await codeData.json();
		if (
			codeData.status === 401 ||
			(codeData.status === 400 && codeDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw codeDataJson;
	}

	await fetchRegistrationInfo();

	return codeData;
}

export async function fetchRegistrationCountries(dictionaryId: string) {
	const countriesData = await customFetch(
		API_ROUTES.DICTIONARY_BY_ID.URL.replace('{dictId}', dictionaryId) +
			'?' +
			new URLSearchParams({
				state: localStorage.getItem('state') as string
			}),
		{
			method: API_ROUTES.DICTIONARY_BY_ID.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonCountriesData = await countriesData.json();
	if (countriesData.status != 200) {
		if (countriesData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (
			countriesData.status === 401 ||
			(countriesData.status === 400 &&
				jsonCountriesData.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw jsonCountriesData;
	}
	return jsonCountriesData;
}

export async function fetchSubmitPhoneVerifyCode(data: {
	phone: string;
	isPhoneVerified: boolean;
	countryCode: string;
}) {
	if (data.isPhoneVerified) {
		const locale =
			window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
		window.location.replace(
			ROUTES.REGISTRATION.SUMMARY.replace(':locale', locale)
		);
	}

	const codeData = await customFetch(API_ROUTES.SEND_CODE.URL, {
		method: API_ROUTES.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			identityType: 'phone',
			identity: data.phone,
			phoneCountryCode: data.countryCode
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (
			codeData.status === 401 ||
			(codeData.status === 400 && codeDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw codeDataJson;
	}

	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	return codeData;
}

export async function fetchAskNewPhoneCode() {
	const codeData = await customFetch(API_ROUTES.SEND_CODE.URL, {
		method: API_ROUTES.SEND_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			identityType: 'phone',
			identity:
				sessionStorage.getItem('phoneCode') +
				'' +
				sessionStorage.getItem('phone')?.replaceAll(' ', ''),
			phoneCountryCode: sessionStorage.getItem('countryCode')
		})
	});

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		if (
			codeData.status === 401 ||
			(codeData.status === 400 && codeDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw codeDataJson;
	}

	sessionStorage.setItem(
		'resendIn',
		String(Math.floor(codeDataJson.resendIn / 1000))
	);

	return codeData;
}

export async function fetchSubmitPhoneCode(data: { code: string }) {
	const codeData = await customFetch(API_ROUTES.VERIFY_CODE.URL, {
		method: API_ROUTES.VERIFY_CODE.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			identityType: 'phone',
			identity:
				sessionStorage.getItem('phoneCode') +
				'' +
				sessionStorage.getItem('phone')?.replaceAll(' ', ''),
			code: data.code
		})
	});

	if (codeData.status != 200) {
		if (codeData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const codeDataJson = await codeData.json();
		if (
			codeData.status === 401 ||
			(codeData.status === 400 && codeDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw codeDataJson;
	}

	await fetchRegistrationInfo();

	return codeData;
}

export async function fetchSubmitLastStep(data: {
	name: string;
	password: string;
	referal: string;
}) {
	const lastStepData = await customFetch(API_ROUTES.CREATE_ACCOUNT.URL, {
		method: API_ROUTES.CREATE_ACCOUNT.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			state: localStorage.getItem('state'),
			password: data.password,
			name: data.name,
			referal: data.referal
		})
	});

	if (lastStepData.status != 200) {
		if (lastStepData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale)
			);
		}
		const lastStepDataJson = await lastStepData.json();
		if (
			lastStepData.status === 401 ||
			(lastStepData.status === 400 &&
				lastStepDataJson.code === 'STATE_INVALID')
		) {
			await updateState();
		}
		throw lastStepDataJson;
	}

	return lastStepData;
}
